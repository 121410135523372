import axios from "axios";
import pageURL from "../utils/pageUrls";
const getLangCode = () => {
  const lang = localStorage.getItem("locale");
  return lang ? lang.toLocaleUpperCase() : "TR";
};
const customAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    platformCode: "admin",
    tenantId: JSON.parse(localStorage?.getItem("selectedFactory")) ? JSON.parse(localStorage?.getItem("selectedFactory"))?.label : 0,
    languageCode: getLangCode(),
    // "Content-Type": "application/json; charset=utf8",
  },
  // //withCredentials: false,
  // timeout: 100000,
  // timeoutErrorMessage: "API erişim sağlanamıyor!",
});

const requestHandler = (request) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const accessToken = JSON.parse(localStorage.getItem("accessToken"));
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  } else if (token) {
    request.headers.Authorization = `Bearer ${token.accessToken}`;
  }
  return request;
};

const responseHandler = (response, history) => {
  return response;
};

const errorHandler = (error) => {
  if (error?.response?.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("selectedRoles");
    localStorage.removeItem("selectedFactory");
    localStorage.removeItem("user");
    window.location.href = `${process.env.REACT_APP_SITE_URL}${pageURL.login}`;
  } else if (error?.response?.status === 403 || error?.response?.data?.HttpStatusCode === 403) {
    window.location.href = `${process.env.REACT_APP_SITE_URL}${pageURL.forbidden}`;
  }
  // else if (error?.response?.status === 500) {
  //   window.location.href = `${process.env.REACT_APP_SITE_URL}${pageURL.home}`;
  // }

  return Promise.reject(error?.response?.data);
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default customAxios;
