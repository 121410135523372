import React from "react";
import pageURL from "./utils/pageUrls";

const Home = React.lazy(() => import("./pages/Home"));

const RoleManagement = React.lazy(() => import("./pages/RoleManagement/index"));
const RoleManagementAction = React.lazy(() => import("./pages/RoleManagement/Action"));

const SprintReport = React.lazy(() => import("./pages/SprintReport/index"));
const SprintReportDetail = React.lazy(() => import("./pages/SprintReport/Action"));

const AnnouncementManagement = React.lazy(() => import("./pages/AnnouncementManagement/index"));
const AnnouncementManagementAction = React.lazy(() => import("./pages/AnnouncementManagement/Action"));

// const WomenManagement = React.lazy(() => import("./pages/WomenManagement/index"));
// const WomenManagementAction = React.lazy(() => import("./pages/WomenManagement/Action"));

const StepCounterManagement = React.lazy(() => import("./pages/StepCounterManagement/index"));
const StepCounterManagementAction = React.lazy(() => import("./pages/StepCounterManagement/Action"));
const StepCounterReportManagement = React.lazy(() => import("./pages/StepCounterManagement/stepReport"));
const StepCounterUserReportManagement = React.lazy(() => import("./pages/StepCounterManagement/userStepReport"));

const AdvertisementManagement = React.lazy(() => import("./pages/AdvertisementManagement/index"));
const AdvertisementManagementAction = React.lazy(() => import("./pages/AdvertisementManagement/Action"));

const LanguageManagement = React.lazy(() => import("./pages/LanguageManagement/index"));
const LanguageManagementAction = React.lazy(() => import("./pages/LanguageManagement/Action"));

const MenuManagement = React.lazy(() => import("./pages/MenuManagement/index"));
const MenuManagementAction = React.lazy(() => import("./pages/MenuManagement/Action"));

// const ReservationManagement = React.lazy(() => import("./pages/ReservationManagement/index"));
// const ReservationManagementAction = React.lazy(() => import("./pages/ReservationManagement/Action"));

const DocumentsManagement = React.lazy(() => import("./pages/DocumentsManagement/index"));
const DocumentsManagementAction = React.lazy(() => import("./pages/DocumentsManagement/Action"));

const ShortcutManagement = React.lazy(() => import("./pages/ShortcutManagement/index"));
const ShortcutManagementAction = React.lazy(() => import("./pages/ShortcutManagement/Action"));

const AppManagement = React.lazy(() => import("./pages/AppManagement/index"));
const AppManagementAction = React.lazy(() => import("./pages/AppManagement/Action"));

const EmergencyManagement = React.lazy(() => import("./pages/EmergencyManagement/index"));

const EducationCategoryManagement = React.lazy(() => import("./pages/EducationCategoryManagement/index"));
const EducationCategoryManagementAction = React.lazy(() => import("./pages/EducationCategoryManagement/Action"));

const CompanyCategoryManagement = React.lazy(() => import("./pages/CompanyCategoryManagement/index"));
const CompanyCategoryManagementAction = React.lazy(() => import("./pages/CompanyCategoryManagement/Action"));

const PhoneManagement = React.lazy(() => import("./pages/PhoneManagement/index"));
const PhoneManagementAction = React.lazy(() => import("./pages/PhoneManagement/Action"));
const PhoneManagementActionAdd = React.lazy(() => import("./pages/PhoneManagement/Add"));

const ServiceManagement = React.lazy(() => import("./pages/ServiceManagement/index"));
const ServiceManagementAction = React.lazy(() => import("./pages/ServiceManagement/Action"));

const FoodManagement = React.lazy(() => import("./pages/FoodManagement/index"));
const FoodManagementAction = React.lazy(() => import("./pages/FoodManagement/Action"));

const NewsManagement = React.lazy(() => import("./pages/NewsManagement/index"));
const NewsManagementAction = React.lazy(() => import("./pages/NewsManagement/Action"));

const NearMissManagement = React.lazy(() => import("./pages/NearMissManagement/index"));
const NearMissManagementAction = React.lazy(() => import("./pages/NearMissManagement/Action"));

const NearMissSectionManagement = React.lazy(() => import("./pages/NearMissSectionManagement/index"));
const NearMissSectionManagementAction = React.lazy(() => import("./pages/NearMissSectionManagement/Action"));

const NearMissSubjectManagement = React.lazy(() => import("./pages/NearMissSubjectManagement/index"));
const NearMissSubjectManagementAction = React.lazy(() => import("./pages/NearMissSubjectManagement/Action"));

const SocialManagement = React.lazy(() => import("./pages/SocialManagement/index"));
const SocialManagementAction = React.lazy(() => import("./pages/SocialManagement/Action"));

const EducationManagement = React.lazy(() => import("./pages/EducationManagement/index"));
const EducationManagementReport = React.lazy(() => import("./pages/EducationManagement/report"));
const EducationManagementAction = React.lazy(() => import("./pages/EducationManagement/Action"));
const EducationManagementReportAnswers = React.lazy(() => import("./pages/EducationManagement/reportanswers"));

const CompanyManagement = React.lazy(() => import("./pages/CompanyManagement/index"));
const CompanyManagementAction = React.lazy(() => import("./pages/CompanyManagement/Action"));

const NotificationManagement = React.lazy(() => import("./pages/NotificationManagement/index"));
const NotificationManagementAction = React.lazy(() => import("./pages/NotificationManagement/Action"));

const QuestionnaireManagement = React.lazy(() => import("./pages/QuestionnaireManagement/index"));
const QuestionnaireManagementExcel = React.lazy(() => import("./pages/QuestionnaireManagement/excel"));
const QuestionnaireManagementReport = React.lazy(() => import("./pages/QuestionnaireManagement/report"));
const QuestionnaireManagementAction = React.lazy(() => import("./pages/QuestionnaireManagement/Action"));

const FeedbackManagement = React.lazy(() => import("./pages/FeedbackManagement/index"));
const FeedbackManagementReport = React.lazy(() => import("./pages/FeedbackManagement/report"));
const FeedBackManagementReport = React.lazy(() => import("./pages/FeedbackManagement/feedReport"));

const UserManagement = React.lazy(() => import("./pages/UserManagement/index"));
const UserManagementAction = React.lazy(() => import("./pages/UserManagement/Action"));

const TenantManagement = React.lazy(() => import("./pages/TenantManagement/index"));
const TenantManagementAction = React.lazy(() => import("./pages/TenantManagement/Action"));

const routes = [
  { path: pageURL.home, exact: true, name: "general.home", component: Home },

  { path: pageURL.roleManagement, exact: true, name: "general.roleManagement", component: RoleManagement, code: "RL001" },
  { path: pageURL.roleManagementAction, exact: true, name: "general.roleManagement", component: RoleManagementAction, code: "RL002" },

  { path: pageURL.sprintReport, exact: true, name: "general.sprintReports", component: SprintReport },
  { path: pageURL.sprintReportDetail, exact: true, name: "general.sprintReports", component: SprintReportDetail },

  { path: pageURL.questionnaireManagement, exact: true, name: "general.questionnaireManagement", component: QuestionnaireManagement, code: "Q001" },
  { path: pageURL.questionnaireManagementAction, exact: true, name: "general.questionnaireManagement", component: QuestionnaireManagementAction, code: "Q002" },
  { path: pageURL.questionnaireManagementReport, exact: true, name: "general.questionnaireManagement", component: QuestionnaireManagementReport, code: "Q002" },
  { path: pageURL.questionnaireManagementExcel, exact: true, name: "general.questionnaireManagement", component: QuestionnaireManagementExcel, code: "Q002" },

  { path: pageURL.feedbackManagement, exact: true, name: "general.feedbackManagement", component: FeedbackManagement, code: "Q001" },
  { path: pageURL.feedbackManagementReport, exact: true, name: "general.feedbackManagement", component: FeedbackManagementReport, code: "Q002" },
  { path: pageURL.feedBackManagementReport, exact: true, name: "general.feedbackManagement", component: FeedBackManagementReport, code: "Q002" },

  { path: pageURL.userManagement, exact: true, name: "general.userManagement", component: UserManagement, code: "U001" },
  { path: pageURL.userManagementAction, exact: true, name: "general.userManagement", component: UserManagementAction, code: "U002" },

  { path: pageURL.tenantManagement, exact: true, name: "general.tenantManagement", component: TenantManagement, code: "T001" },
  { path: pageURL.tenantManagementAction, exact: true, name: "general.tenantManagement", component: TenantManagementAction, code: "T002" },

  { path: pageURL.announcementManagement, exact: true, name: "general.announcementManagement", component: AnnouncementManagement, code: "AN001" },
  { path: pageURL.announcementManagementAction, exact: true, name: "general.announcementManagement", component: AnnouncementManagementAction, code: "AN002" },

  // { path: pageURL.womenManagement, exact: true, name: "general.womenManagement", component: WomenManagement, code: "AN001" },
  // { path: pageURL.womenManagementAction, exact: true, name: "general.womenManagement", component: WomenManagementAction, code: "AN002" },

  { path: pageURL.stepCounterManagement, exact: true, name: "general.stepCounterManagement", component: StepCounterManagement, code: "AN001" },
  { path: pageURL.stepCounterManagementAction, exact: true, name: "general.stepCounterManagement", component: StepCounterManagementAction, code: "AN002" },
  { path: pageURL.stepCounterReportManagement, exact: true, name: "general.stepCounterManagement", component: StepCounterReportManagement, code: "AN002" },
  { path: pageURL.stepCounterUserReportManagement, exact: true, name: "general.stepCounterManagement", component: StepCounterUserReportManagement, code: "AN002" },

  { path: pageURL.advertisementManagement, exact: true, name: "general.advertisementManagement", component: AdvertisementManagement, code: "AD001" },
  { path: pageURL.advertisementManagementAction, exact: true, name: "general.advertisementManagement", component: AdvertisementManagementAction, code: "AD002" },

  { path: pageURL.languageManagement, exact: true, name: "general.languageManagement", component: LanguageManagement, code: "asd" },
  { path: pageURL.languageManagementAction, exact: true, name: "general.languageManagement", component: LanguageManagementAction, code: "asd" },

  { path: pageURL.menuManagement, exact: true, name: "general.menuManagement", component: MenuManagement, code: "F001" },
  { path: pageURL.menuManagementAction, exact: true, name: "general.menuManagement", component: MenuManagementAction, code: "F002" },

  // { path: pageURL.reservationManagement, exact: true, name: "general.reservationManagement", component: ReservationManagement, code: "R001" },
  // { path: pageURL.reservationManagementAction, exact: true, name: "general.reservationManagement", component: ReservationManagementAction, code: "R002" },

  { path: pageURL.shortcutManagement, exact: true, name: "general.shortcutManagement", component: ShortcutManagement, code: "SH001" },
  { path: pageURL.shortcutManagementAction, exact: true, name: "general.shortcutManagement", component: ShortcutManagementAction, code: "SH002" },
  
  { path: pageURL.documentsManagement, exact: true, name: "general.prosedureAndDocuments", component: DocumentsManagement, code: "SH001" },
  { path: pageURL.documentsManagementAction, exact: true, name: "general.prosedureAndDocuments", component: DocumentsManagementAction, code: "SH002" },

  { path: pageURL.appManagement, exact: true, name: "general.appManagement", component: AppManagement, code: "SH001" },
  { path: pageURL.appManagementAction, exact: true, name: "general.appManagement", component: AppManagementAction, code: "SH002" },

  { path: pageURL.emergencyManagement, exact: true, name: "general.emergencyManagement", component: EmergencyManagement, code: "SH001" },

  { path: pageURL.educationCategoryManagement, exact: true, name: "general.educationCategoryManagement", component: EducationCategoryManagement, code: "SH001" },
  { path: pageURL.educationCategoryManagementAction, exact: true, name: "general.educationCategoryManagement", component: EducationCategoryManagementAction, code: "SH002" },

  { path: pageURL.companyCategoryManagement, exact: true, name: "general.companyCategoryManagement", component: CompanyCategoryManagement, code: "SH001" },
  { path: pageURL.companyCategoryManagementAction, exact: true, name: "general.companyCategoryManagement", component: CompanyCategoryManagementAction, code: "SH002" },

  { path: pageURL.phoneManagement, exact: true, name: "general.phoneManagement", component: PhoneManagement, code: "asd" },
  { path: pageURL.phoneManagementAction, exact: true, name: "general.phoneManagement", component: PhoneManagementAction, code: "asd" },
  { path: pageURL.phoneManagementActionAdd, exact: true, name: "general.phoneManagement", component: PhoneManagementActionAdd, code: "asd" },

  { path: pageURL.serviceManagement, exact: true, name: "general.serviceManagement", component: ServiceManagement, code: "S001" },
  { path: pageURL.serviceManagementAction, exact: true, name: "general.serviceManagement", component: ServiceManagementAction, code: "S002" },

  { path: pageURL.nearMissSectionManagement, exact: true, name: "general.nearMissSectionManagement", component: NearMissSectionManagement, code: "S001" },
  { path: pageURL.nearMissSectionManagementAction, exact: true, name: "general.nearMissSectionManagement", component: NearMissSectionManagementAction, code: "S002" },

  { path: pageURL.nearMissSubjectManagement, exact: true, name: "general.nearMissSubjectManagement", component: NearMissSubjectManagement, code: "S001" },
  { path: pageURL.nearMissSubjectManagementAction, exact: true, name: "general.nearMissSubjectManagement", component: NearMissSubjectManagementAction, code: "S002" },

  { path: pageURL.foodManagement, exact: true, name: "general.foodManagement", component: FoodManagement, code: "F001" },
  { path: pageURL.foodManagementAction, exact: true, name: "general.foodManagement", component: FoodManagementAction, code: "F002" },

  { path: pageURL.newsManagement, exact: true, name: "general.newsManagement", component: NewsManagement, code: "N001" },
  { path: pageURL.newsManagementAction, exact: true, name: "general.newsManagement", component: NewsManagementAction, code: "N002" },

  { path: pageURL.nearMissManagement, exact: true, name: "general.nearMissReport", component: NearMissManagement, code: "N001" },
  { path: pageURL.nearMissManagementAction, exact: true, name: "general.nearMissReport", component: NearMissManagementAction, code: "N002" },

  { path: pageURL.socialManagement, exact: true, name: "general.socialManagement", component: SocialManagement, code: "N001" },
  { path: pageURL.socialManagementAction, exact: true, name: "general.socialManagement", component: SocialManagementAction, code: "N002" },

  { path: pageURL.educationManagement, exact: true, name: "general.educationManagement", component: EducationManagement, code: "N001" },
  { path: pageURL.educationManagementReport, exact: true, name: "general.EducationManagement", component: EducationManagementReport, code: "N001" },
  { path: pageURL.educationManagementAction, exact: true, name: "general.educationManagement", component: EducationManagementAction, code: "N002" },
  { path: pageURL.EducationManagementReportAnswers, exact: true, name: "general.educationManagement", component: EducationManagementReportAnswers, code: "N002" },
  

  { path: pageURL.companyManagement, exact: true, name: "general.companyManagement", component: CompanyManagement, code: "N001" },
  { path: pageURL.companyManagementAction, exact: true, name: "general.companyManagement", component: CompanyManagementAction, code: "N002" },

  { path: pageURL.notificationManagement, exact: true, name: "general.notificationManagement", component: NotificationManagement, code: "N001" },
  { path: pageURL.notificationManagementAction, exact: true, name: "general.notificationManagement", component: NotificationManagementAction, code: "N001" },
];

export default routes;
