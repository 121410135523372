const pageURL = {
  home: "/",
  login: "/login",
  forgetPassword: "/forget-password",
  generatePassword: "/generate",
  changePasswordForget: "/change-password-forget",
  changePassword: "/change-password",

  sprintManagement: "/sprint-management",
  sprintManagementAction: "/sprint-management/:mode/:id?",

  projectManagement: "/project-management",
  projectManagementAction: "/project-management/:mode/:id?",

  staffManagement: "/staff-management",
  staffManagementAction: "/staff-management/:mode/:id?",

  customerManagement: "/customer-management",
  customerManagementAction: "/customer-management/:mode/:id?",

  teamManagement: "/team-management",
  teamManagementAction: "/team-management/:mode/:id?",

  authorityManagement: "/authority-management",

  roleManagement: "/role-management",
  roleManagementAction: "/role-management/:mode/:id?",

  titleManagement: "/title-management",

  sprintReport: "/sprint-report",
  sprintReportDetail: "/sprint-report/:mode/:id",

  eventManagement: "/event-management",
  eventManagementAction: "/event-management/:mode/:id?",

  retroReport: "/retro-report",
  retroReportDetail: "/retro-report/:mode/:id?",

  companyManagement: "/company-management",
  companyManagementAction: "/company-management/:mode/:id?",

  agreementManagement: "/agreement-management",
  agreementManagementAction: "/agreement-management/:mode/:id?",

  announcementManagement: "/announcement-management",
  announcementManagementAction: "/announcement-management/:mode/:id?",

  // womenManagement: "/women-management",
  // womenManagementAction: "/women-management/:mode/:id?",

  stepCounterManagement: "/stepCounter-management",
  stepCounterReportManagement: "/stepCounter-management/detail/report/:id?",
  stepCounterUserReportManagement: "/stepCounter-management/detail/report/user/:id?/:userId?",
  stepCounterManagementAction: "/stepCounter-management/:mode/:id?",

  advertisementManagement: "/advertisement-management",
  advertisementManagementAction: "/advertisement-management/:mode/:id?",

  languageManagement: "/language-management",
  languageManagementAction: "/language-management/:mode/:id?",

  foodManagement: "/food-management",
  foodManagementAction: "/food-management/:mode/:id?",

  menuManagement: "/menu-management",
  menuManagementAction: "/menu-management/:mode/:id?",

  // reservationManagement: "/reservation-management",
  // reservationManagementAction: "/reservation-management/:mode/:id?",

  shortcutManagement: "/shortcut-management",
  shortcutManagementAction: "/shortcut-management/:mode/:id?",

  documentsManagement: "/documents-management",
  documentsManagementAction: "/documents-management/:mode/:id?",

  appManagement: "/app-management",
  appManagementAction: "/app-management/:mode/:id?",

  emergencyManagement: "/emergency-management",

  phoneManagement: "/phone-management",
  phoneManagementActionAdd: "/phone-management/insert/excel/phone",
  phoneManagementAction: "/phone-management/:mode/:id?",

  serviceManagement: "/service-management",
  serviceManagementAction: "/service-management/:mode/:id?",

  newsManagement: "/news-management",
  newsManagementAction: "/news-management/:mode/:id?",

  nearMissManagement: "/near-miss-management",
  nearMissManagementAction: "/near-miss-management/report/:id?",

  socialManagement: "/social-management",
  socialManagementAction: "/social-management/:mode/:id?",

  educationManagement: "/education-management",
  educationManagementReport: "/education-management/get/report/:id?",
  EducationManagementReportAnswers: "/education-management/get/answers/:id?/user/:userId?",
  educationManagementAction: "/education-management/:mode/:id?",

  educationCategoryManagement: "/education-category-management",
  educationCategoryManagementAction: "/education-category-management/:mode/:id?",

  companyCategoryManagement: "/company-category-management",
  companyCategoryManagementAction: "/company-category-management/:mode/:id?",

  notificationManagement: "/notification-management",
  notificationManagementAction: "/notification-management/:mode/:id?",

  userManagement: "/user-management",
  userManagementAction: "/user-management/:mode/:id?",

  tenantManagement: "/tenant-management",
  tenantManagementAction: "/tenant-management/:mode/:id?",

  billingManagement: "/billing-management",
  billingManagementAction: "/billing-management/:mode/:id?",

  nearMissSectionManagement: "/missSection-management",
  nearMissSectionManagementAction: "/missSection-management/:mode/:id?",

  nearMissSubjectManagement: "/missSubject-management",
  nearMissSubjectManagementAction: "/missSubject-management/:mode/:id?",

  questionnaireManagement: "/questionnaire-management",
  questionnaireManagementReport: "/questionnaire-management/detail/report/:id?",
  questionnaireManagementAction: "/questionnaire-management/:mode/:id?",
  questionnaireManagementExcel: "/questionnaire-management/:mode/importExcel/bulk",

  feedbackManagement: "/feedback-management",
  feedbackManagementReport: "/feedback-management/detail/report/:id?",
  feedBackManagementReport: "/feedback-management/showDetail/report/:id?",

  downloadManagement: "/Download",
  downloadManagementIos: "/Download/Ios",
  forbidden: "/forbidden",
};

export default pageURL;
